export const soundcloudData = {
  duration: 3958828,
  release_day: null,
  permalink_url: 'https://soundcloud.com/audiosprite/sets/reel',
  genre: 'game',
  permalink: 'reel',
  purchase_url: null,
  release_month: null,
  description: 'Some of my favorites! See www.audiosprite.com.',
  tags: '"vgm"',
  uri: 'https://api.soundcloud.com/playlists/310569779',
  label_name: null,
  label: null,
  tag_list: '"vgm"',
  release_year: null,
  track_count: 32,
  user_id: 2712198,
  last_modified: '2021/03/22 14:22:27 +0000',
  license: 'all-rights-reserved',
  tracks: [
    {
      kind: 'track',
      id: 507496890,
      created_at: '2018/09/30 20:09:37 +0000',
      user_id: 2712198,
      duration: 204139,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 35993622,
      last_modified: '2021/03/19 20:05:31 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'ripe-taffy',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'jazzhop',
      title: 'Zarvot - Ripe Taffy',
      description:
        'Hear effusive praise("music to do heroin to!") of "Ripe Taffy" by the RebelFM crew (Arthur Gies, Anthony Gallegos, James Faulkner, Brian Altano, Matt Chandronait) at 2:58:40 here:\n\nhttps://rebelfm.libsyn.com/the-rebel-fm-2018-game-music-spectacular',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/507496890',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/ripe-taffy',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000413040852-ewk63d-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/507496890/stream',
      download_url: 'https://api.soundcloud.com/tracks/507496890/download',
      waveform_url: 'https://wave.sndcdn.com/xXLPsXZgMMbn_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 137,
      download_count: 0,
      favoritings_count: 2,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 795479794,
      created_at: '2020/04/10 17:37:35 +0000',
      user_id: 2712198,
      duration: 164780,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 29063814,
      last_modified: '2020/04/10 17:39:35 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'kungfu-temple-temple',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'spiritual jazz',
      title: 'KungFu Kickball - Temple',
      description: '',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/795479794',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/kungfu-temple-temple',
      artwork_url:
        'https://i1.sndcdn.com/artworks-RZfLHw2VhDWQvs7V-dXNTRQ-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/795479794/stream',
      download_url: 'https://api.soundcloud.com/tracks/795479794/download',
      waveform_url: 'https://wave.sndcdn.com/BFlo2JwOh0bR_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 44,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 911695321,
      created_at: '2020/10/16 15:09:57 +0000',
      user_id: 2712198,
      duration: 82129,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 15763054,
      last_modified: '2020/12/25 04:17:06 +0000',
      sharing: 'public',
      tag_list: 'chiptune',
      permalink: 'super-crome-bullet-purgatory-4',
      streamable: true,
      embeddable_by: 'all',
      purchase_url:
        'https://audiosprite.bandcamp.com/album/super-crome-bullet-purgatory-ep',
      purchase_title: null,
      label_id: null,
      genre: 'chiptune',
      title: 'Super Crome: Bullet Purgatory - Crestfallen',
      description:
        'Soundtrack EP for space shmup SUPER CROME: BULLET PURGATORY now available in early access on Steam: https://store.steampowered.com/app/984450/Super_Crome_Bullet_Purgatory/',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2020,
      release_month: 10,
      release_day: 14,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/911695321',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/super-crome-bullet-purgatory-4',
      artwork_url:
        'https://i1.sndcdn.com/artworks-yTQiNzBLkm5ky6nW-RGNBFA-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/911695321/stream',
      download_url: 'https://api.soundcloud.com/tracks/911695321/download',
      waveform_url: 'https://wave.sndcdn.com/PWidD2me3DhB_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 38,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 507496875,
      created_at: '2018/09/30 20:09:36 +0000',
      user_id: 2712198,
      duration: 214067,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 37746594,
      last_modified: '2020/07/30 21:44:30 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'villa-banana-nova-1',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'indie lofi',
      title: 'Zarvot - Villa Banana Nova',
      description: '',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/507496875',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/villa-banana-nova-1',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000413042835-d6dpzb-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/507496875/stream',
      download_url: 'https://api.soundcloud.com/tracks/507496875/download',
      waveform_url: 'https://wave.sndcdn.com/wjnWj2COQsED_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 436,
      download_count: 0,
      favoritings_count: 6,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 948125872,
      created_at: '2020/12/15 19:51:01 +0000',
      user_id: 2712198,
      duration: 89966,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 25897992,
      last_modified: '2021/02/14 17:11:55 +0000',
      sharing: 'public',
      tag_list: 'Metal',
      permalink: 'wholesome-slaughter-fight-back',
      streamable: true,
      embeddable_by: 'all',
      purchase_url:
        'https://audiosprite.bandcamp.com/album/wholesome-slaughter-original-game-soundtrack',
      purchase_title: null,
      label_id: null,
      genre: 'Metal',
      title: 'Wholesome Slaughter - Fight Back',
      description:
        'Download Wholesome Slaughter by Eclipse Game Labs on itch! https://gunshot25.itch.io/wholesome-slaughter\n\nDownload the Wholesome Slaughter OST on Bandcamp! https://distrokid.com/hyperfollow/johnfio/wholesome-slaughter-original-game-soundtrack\n\nAll tracks written, arranged, performed, recorded, and mixed by John Fio\n\n2-5 mastered by Charlie Scourbys\nUnicorn art by Jack DeCesare',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2020,
      release_month: 12,
      release_day: 9,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/948125872',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/wholesome-slaughter-fight-back',
      artwork_url:
        'https://i1.sndcdn.com/artworks-FJ408RwseQi6R5Wa-2lA57g-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/948125872/stream',
      download_url: 'https://api.soundcloud.com/tracks/948125872/download',
      waveform_url: 'https://wave.sndcdn.com/eKskW4x7l16E_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 115,
      download_count: 0,
      favoritings_count: 2,
      reposts_count: 1,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 437122827,
      created_at: '2018/04/30 03:17:10 +0000',
      user_id: 2712198,
      duration: 75630,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 21761962,
      last_modified: '2018/09/30 16:22:51 +0000',
      sharing: 'public',
      tag_list: 'shortfilm',
      permalink: 'drip',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'electronic',
      title: 'Drip',
      description: 'shortfilm ost https://www.instagram.com/p/BiLdx4dgE9s/',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/437122827',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/drip',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000342282120-i1eyg9-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/437122827/stream',
      download_url: 'https://api.soundcloud.com/tracks/437122827/download',
      waveform_url: 'https://wave.sndcdn.com/rT36vWKtelxT_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 159,
      download_count: 0,
      favoritings_count: 3,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 507496920,
      created_at: '2018/09/30 20:09:40 +0000',
      user_id: 2712198,
      duration: 134202,
      commentable: true,
      comment_count: 1,
      state: 'finished',
      original_content_size: 23660582,
      last_modified: '2020/07/30 21:46:55 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'ps118',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'lofi/jazzhop',
      title: 'Zarvot - PS118',
      description: '',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/507496920',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/ps118',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000417799905-ri06ac-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/507496920/stream',
      download_url: 'https://api.soundcloud.com/tracks/507496920/download',
      waveform_url: 'https://wave.sndcdn.com/wRbRREazhc3G_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 97,
      download_count: 0,
      favoritings_count: 2,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 206468640,
      created_at: '2015/05/21 01:19:40 +0000',
      user_id: 2712198,
      duration: 210958,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 42024636,
      last_modified: '2020/07/30 21:48:55 +0000',
      sharing: 'public',
      tag_list: 'epanalepsis ost videogame game gamemusic "soundtrack " idm',
      permalink: '03-rachel',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: 'https://audiosprite.bandcamp.com/album/epanalepsis',
      purchase_title: null,
      label_id: null,
      genre: 'downtempo',
      title: 'Epanalepsis - Rachel',
      description:
        'The soundtrack to Cameron Kunzelman\'s literary adventure game of the same name. store.steampowered.com/app/333690/\ncredits\nreleased 21 May 2015 \n\nJohn Fio: clarinet, bari sax, acoustic guitar, bass guitar, piano, drums, electric guitar, all programming \nSam Tobias: flute, alto sax \nDan Filipak: double bass \nKyle Kassick: additional guitars on "Heaven" \n\nrecorded and mixed by John Fio \nmastered by Jose Ignacio Alfaro',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2015,
      release_month: 5,
      release_day: 13,
      original_format: 'flac',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/206468640',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/03-rachel',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000362275011-vfz4dj-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/206468640/stream',
      download_url: 'https://api.soundcloud.com/tracks/206468640/download',
      waveform_url: 'https://wave.sndcdn.com/f1sT6iLmCupq_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 441,
      download_count: 0,
      favoritings_count: 4,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 398078457,
      created_at: '2018/02/12 01:46:52 +0000',
      user_id: 2712198,
      duration: 11155,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 3198860,
      last_modified: '2018/02/28 17:07:10 +0000',
      sharing: 'public',
      tag_list: 'classical chamber quartet string',
      permalink: 'roll-for-crit-string-quartet',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'string quartet',
      title: 'Roll for Crit (string quartet)',
      description:
        'Genre variations of the Roll for Crit video & podcast series theme.\n\nhttps://www.youtube.com/user/RollForCritCast',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/398078457',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/roll-for-crit-string-quartet',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000300757383-jeu1kh-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/398078457/stream',
      download_url: 'https://api.soundcloud.com/tracks/398078457/download',
      waveform_url: 'https://wave.sndcdn.com/qI9DdmSOuTu5_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 167,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 785019022,
      created_at: '2020/03/28 01:47:02 +0000',
      user_id: 2712198,
      duration: 137064,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 24171532,
      last_modified: '2020/04/10 17:39:16 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'character-select',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'funk orchestra',
      title: 'KungFu Kickball - Character Select',
      description: '',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/785019022',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/character-select',
      artwork_url:
        'https://i1.sndcdn.com/artworks-RZfLHw2VhDWQvs7V-dXNTRQ-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/785019022/stream',
      download_url: 'https://api.soundcloud.com/tracks/785019022/download',
      waveform_url: 'https://wave.sndcdn.com/hDOSEHO5GUvI_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 127,
      download_count: 0,
      favoritings_count: 1,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 133032637,
      created_at: '2014/02/04 16:23:19 +0000',
      user_id: 2712198,
      duration: 297692,
      commentable: true,
      comment_count: 2,
      state: 'finished',
      original_content_size: 11895336,
      last_modified: '2014/08/09 18:52:43 +0000',
      sharing: 'public',
      tag_list: 'piano solo "solo piano" jazz',
      permalink: 'broomstick-waltz',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'pop jazz',
      title: 'Broomstick Waltz',
      description:
        'This is the first tune I ever wrote. Three years ago I went to a studio with a couple of friends and we recorded a whole bunch of takes of this thing with different combos and I forgot to post it OOPS\r\n\r\nPiano: Ben Russo\r\nAlbum art by jtavormina.tumblr.com',
      label_name: '',
      release: '',
      track_type: 'other',
      key_signature: '',
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'mp3',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/133032637',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/broomstick-waltz',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000069895860-e96o2t-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/133032637/stream',
      download_url: 'https://api.soundcloud.com/tracks/133032637/download',
      waveform_url: 'https://wave.sndcdn.com/oalSSlT0W19y_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 418,
      download_count: 5,
      favoritings_count: 6,
      reposts_count: 0,
      downloadable: true,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 398078460,
      created_at: '2018/02/12 01:46:52 +0000',
      user_id: 2712198,
      duration: 10083,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 2890350,
      last_modified: '2018/02/12 01:54:27 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'roll-for-crit-funk',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'funk',
      title: 'Roll for Crit (funk)',
      description:
        'Genre variations of the Roll for Crit video & podcast series theme.\n\nhttps://www.youtube.com/user/RollForCritCast',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/398078460',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/roll-for-crit-funk',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000300757428-6i841o-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/398078460/stream',
      download_url: 'https://api.soundcloud.com/tracks/398078460/download',
      waveform_url: 'https://wave.sndcdn.com/yQbrDiFLo7dX_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 124,
      download_count: 0,
      favoritings_count: 2,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 820757104,
      created_at: '2020/05/15 01:28:40 +0000',
      user_id: 2712198,
      duration: 196545,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 34664844,
      last_modified: '2020/05/15 16:39:26 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'roll-control-frisky-amahiki',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'hawaiian',
      title: 'Roll Control - Frisky Amahiki',
      description:
        'written and performed by John Fio\nmastered by Charlie Scourbys\n\nPlay Roll Control by Andrew Simon Thomas: https://andrew-simon-thomas-llc.itch.io/roll-control',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/820757104',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/roll-control-frisky-amahiki',
      artwork_url:
        'https://i1.sndcdn.com/artworks-Qcb4isyjzCv2qW9z-L7Lmkg-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/820757104/stream',
      download_url: 'https://api.soundcloud.com/tracks/820757104/download',
      waveform_url: 'https://wave.sndcdn.com/c4qC28HG8VvS_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 119,
      download_count: 0,
      favoritings_count: 1,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 785019034,
      created_at: '2020/03/28 01:47:04 +0000',
      user_id: 2712198,
      duration: 154984,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 27335658,
      last_modified: '2020/04/10 17:39:09 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'bamboogie',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'afrobeat',
      title: 'KungFu Kickball - Bamboogie',
      description: '',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/785019034',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/bamboogie',
      artwork_url:
        'https://i1.sndcdn.com/artworks-RZfLHw2VhDWQvs7V-dXNTRQ-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/785019034/stream',
      download_url: 'https://api.soundcloud.com/tracks/785019034/download',
      waveform_url: 'https://wave.sndcdn.com/CXSBXQUJbiVo_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 40,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 450575700,
      created_at: '2018/05/29 04:04:36 +0000',
      user_id: 2712198,
      duration: 46267,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 1846836,
      last_modified: '2018/05/29 04:04:37 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'gold-skulltula',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'idm',
      title: 'Gold Skulltula',
      description: '',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'mp3',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/450575700',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/gold-skulltula',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000353959533-pesgup-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/450575700/stream',
      download_url: 'https://api.soundcloud.com/tracks/450575700/download',
      waveform_url: 'https://wave.sndcdn.com/iohVm90aX1Zu_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 124,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 206468632,
      created_at: '2015/05/21 01:19:37 +0000',
      user_id: 2712198,
      duration: 159257,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 31478812,
      last_modified: '2020/07/30 21:49:08 +0000',
      sharing: 'public',
      tag_list: 'epanalepsis ost videogame game gamemusic soundtrack',
      permalink: '08-cascabel-and-pasus',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: 'https://audiosprite.bandcamp.com/album/epanalepsis',
      purchase_title: null,
      label_id: null,
      genre: 'chamber',
      title: 'Epanalepsis - Cascabel and Pasus',
      description:
        'The soundtrack to Cameron Kunzelman\'s challenging and literary adventure game of the same name. store.steampowered.com/app/333690/\ncredits\nreleased 21 May 2015 \n\nJohn Fio: clarinet, bari sax, acoustic guitar, bass guitar, piano, drums, electric guitar, all programming \nSam Tobias: flute, alto sax \nDan Filipak: double bass \nKyle Kassick: additional guitars on "Heaven" \n\nrecorded and mixed by John Fio \nmastered by Jose Ignacio Alfaro',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2015,
      release_month: 5,
      release_day: 16,
      original_format: 'flac',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/206468632',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/08-cascabel-and-pasus',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000271272629-dzfpui-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/206468632/stream',
      download_url: 'https://api.soundcloud.com/tracks/206468632/download',
      waveform_url: 'https://wave.sndcdn.com/h8Jbhq73E3OU_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 263,
      download_count: 0,
      favoritings_count: 3,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 617257452,
      created_at: '2019/05/08 00:00:17 +0000',
      user_id: 2712198,
      duration: 65077,
      commentable: true,
      comment_count: 2,
      state: 'finished',
      original_content_size: 18721976,
      last_modified: '2021/01/05 06:04:42 +0000',
      sharing: 'public',
      tag_list: '"animal crossing" chiptune chip electronic "lofi " indie',
      permalink: 'bento-school-days',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'town',
      title: 'Bento Banana - Bento School Days',
      description:
        'Animal Crossing-style track produced for Bento Banana Ep2: Doki Doki Artichoki by Ari Grabb - https://www.youtube.com/watch?v=pjWiyz6s7yA',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/617257452',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/bento-school-days',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000531381015-5y4cgy-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/617257452/stream',
      download_url: 'https://api.soundcloud.com/tracks/617257452/download',
      waveform_url: 'https://wave.sndcdn.com/ouTZAZGUheIe_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 119,
      download_count: 0,
      favoritings_count: 1,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 911695306,
      created_at: '2020/10/16 15:09:55 +0000',
      user_id: 2712198,
      duration: 66508,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 12763570,
      last_modified: '2020/10/22 13:58:07 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'super-crome-bullet-purgatory-3',
      streamable: true,
      embeddable_by: 'all',
      purchase_url:
        'https://audiosprite.bandcamp.com/album/super-crome-bullet-purgatory-ep',
      purchase_title: null,
      label_id: null,
      genre: 'chiptune',
      title: 'Super Crome: Bullet Purgatory - Bozka',
      description:
        'Soundtrack EP for space shmup SUPER CROME: BULLET PURGATORY now available in early access on Steam: https://store.steampowered.com/app/984450/Super_Crome_Bullet_Purgatory/',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2020,
      release_month: 10,
      release_day: 15,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/911695306',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/super-crome-bullet-purgatory-3',
      artwork_url:
        'https://i1.sndcdn.com/artworks-yTQiNzBLkm5ky6nW-RGNBFA-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/911695306/stream',
      download_url: 'https://api.soundcloud.com/tracks/911695306/download',
      waveform_url: 'https://wave.sndcdn.com/9agS0j5FiYNF_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 120,
      download_count: 0,
      favoritings_count: 1,
      reposts_count: 1,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 948125884,
      created_at: '2020/12/15 19:51:02 +0000',
      user_id: 2712198,
      duration: 59455,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 17115100,
      last_modified: '2021/02/14 17:11:46 +0000',
      sharing: 'public',
      tag_list: 'Metal',
      permalink: 'wholesome-slaughter-bubble',
      streamable: true,
      embeddable_by: 'all',
      purchase_url:
        'https://audiosprite.bandcamp.com/album/wholesome-slaughter-original-game-soundtrack',
      purchase_title: null,
      label_id: null,
      genre: 'Metal',
      title: 'Wholesome Slaughter - Bubble Faerie',
      description:
        'Download Wholesome Slaughter by Eclipse Game Labs on itch! https://gunshot25.itch.io/wholesome-slaughter\n\nDownload the Wholesome Slaughter OST on Bandcamp! https://distrokid.com/hyperfollow/johnfio/wholesome-slaughter-original-game-soundtrack\n\nAll tracks written, arranged, performed, recorded, and mixed by John Fio\n\n2-5 mastered by Charlie Scourbys\nUnicorn art by Jack DeCesare',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2020,
      release_month: 12,
      release_day: 9,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/948125884',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/wholesome-slaughter-bubble',
      artwork_url:
        'https://i1.sndcdn.com/artworks-FJ408RwseQi6R5Wa-2lA57g-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/948125884/stream',
      download_url: 'https://api.soundcloud.com/tracks/948125884/download',
      waveform_url: 'https://wave.sndcdn.com/bQnBCOHqnNlV_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 18,
      download_count: 0,
      favoritings_count: 2,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 407425041,
      created_at: '2018/03/02 04:57:39 +0000',
      user_id: 2712198,
      duration: 21108,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 6063338,
      last_modified: '2018/06/18 18:09:37 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'mages-murderdads-season-5',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'postrock',
      title: 'Mages & Murderdads (Season 5)',
      description: 'Feat @ginamoffit.',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/407425041',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/mages-murderdads-season-5',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000362253495-mca3lg-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/407425041/stream',
      download_url: 'https://api.soundcloud.com/tracks/407425041/download',
      waveform_url: 'https://wave.sndcdn.com/fOZ2A9B7UEAZ_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 250,
      download_count: 0,
      favoritings_count: 6,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 795474208,
      created_at: '2020/04/10 17:29:55 +0000',
      user_id: 2712198,
      duration: 69773,
      commentable: true,
      comment_count: 1,
      state: 'finished',
      original_content_size: 12303382,
      last_modified: '2020/04/14 07:13:27 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'kfk-menu-ambience',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'Ambient',
      title: 'KungFu Kickball - Menu Ambience',
      description: '',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/795474208',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/kfk-menu-ambience',
      artwork_url:
        'https://i1.sndcdn.com/artworks-RZfLHw2VhDWQvs7V-dXNTRQ-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/795474208/stream',
      download_url: 'https://api.soundcloud.com/tracks/795474208/download',
      waveform_url: 'https://wave.sndcdn.com/qMHg2lsqIqhn_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 34,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 206468644,
      created_at: '2015/05/21 01:19:41 +0000',
      user_id: 2712198,
      duration: 229507,
      commentable: true,
      comment_count: 1,
      state: 'finished',
      original_content_size: 45819974,
      last_modified: '2020/07/30 21:48:50 +0000',
      sharing: 'public',
      tag_list: 'epanalepsis ost videogame game gamemusic soundtrack',
      permalink: '02-stapled-zines-and-a-missing',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: 'https://audiosprite.bandcamp.com/album/epanalepsis',
      purchase_title: null,
      label_id: null,
      genre: 'acoustic grunge',
      title: 'Epanalepsis - Stapled Zines and a Missing Microwave',
      description:
        'The soundtrack to Cameron Kunzelman\'s challenging and literary adventure game of the same name. store.steampowered.com/app/333690/\ncredits\nreleased 21 May 2015 \n\nJohn Fio: clarinet, bari sax, acoustic guitar, bass guitar, piano, drums, electric guitar, all programming \nSam Tobias: flute, alto sax \nDan Filipak: double bass \nKyle Kassick: additional guitars on "Heaven" \n\nrecorded and mixed by John Fio \nmastered by Jose Ignacio Alfaro',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2015,
      release_month: 5,
      release_day: 16,
      original_format: 'flac',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/206468644',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/02-stapled-zines-and-a-missing',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000362272743-gja62l-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/206468644/stream',
      download_url: 'https://api.soundcloud.com/tracks/206468644/download',
      waveform_url: 'https://wave.sndcdn.com/myahODhTaW1F_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 320,
      download_count: 0,
      favoritings_count: 2,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 515190114,
      created_at: '2018/10/16 15:54:55 +0000',
      user_id: 2712198,
      duration: 214092,
      commentable: true,
      comment_count: 2,
      state: 'finished',
      original_content_size: 37751580,
      last_modified: '2020/07/30 21:44:10 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'solo-end-credits',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'lofi postrock',
      title: 'Zarvot - Solo (End Credits)',
      description:
        "There's so much feelings in being a cube. Zarvot coming to Nintendo Switch this Thursday 10/18/18. https://www.nintendo.com/games/detail/zarvot-switch\n\nMastered by Stephen Kurpis at Vitruvian Sound",
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/515190114',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/solo-end-credits',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000422023143-2apn35-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/515190114/stream',
      download_url: 'https://api.soundcloud.com/tracks/515190114/download',
      waveform_url: 'https://wave.sndcdn.com/I3RBkw2UPu1B_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 393,
      download_count: 0,
      favoritings_count: 5,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 206468698,
      created_at: '2015/05/21 01:20:09 +0000',
      user_id: 2712198,
      duration: 93239,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 16506515,
      last_modified: '2020/07/30 21:48:29 +0000',
      sharing: 'public',
      tag_list: 'epanalepsis ost videogame game gamemusic soundtrack',
      permalink: '10-underground',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: 'https://audiosprite.bandcamp.com/album/epanalepsis',
      purchase_title: null,
      label_id: null,
      genre: 'ambient',
      title: 'Epanalepsis - Underground',
      description:
        'The soundtrack to Cameron Kunzelman\'s challenging and literary adventure game of the same name. store.steampowered.com/app/333690/\ncredits\nreleased 21 May 2015 \n\nJohn Fio: clarinet, bari sax, acoustic guitar, bass guitar, piano, drums, electric guitar, all programming \nSam Tobias: flute, alto sax \nDan Filipak: double bass \nKyle Kassick: additional guitars on "Heaven" \n\nrecorded and mixed by John Fio \nmastered by Jose Ignacio Alfaro',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2015,
      release_month: 5,
      release_day: 17,
      original_format: 'flac',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/206468698',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/10-underground',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000362270787-obglkf-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/206468698/stream',
      download_url: 'https://api.soundcloud.com/tracks/206468698/download',
      waveform_url: 'https://wave.sndcdn.com/fGCi4tFSHJ5d_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 289,
      download_count: 0,
      favoritings_count: 3,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 276317307,
      created_at: '2016/08/01 13:15:25 +0000',
      user_id: 2712198,
      duration: 140708,
      commentable: true,
      comment_count: 1,
      state: 'finished',
      original_content_size: 5625600,
      last_modified: '2016/08/02 12:05:33 +0000',
      sharing: 'public',
      tag_list: 'soundtrack game',
      permalink: 'mangoman',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'chiptune',
      title: 'Mangoman',
      description:
        'Commission for a megamanlike of the same name by Joseph Gribbin. Play for free here ! https://josephgribbin.itch.io/mangoman',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'mp3',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/276317307',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/mangoman',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000174293328-9zv01w-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/276317307/stream',
      download_url: 'https://api.soundcloud.com/tracks/276317307/download',
      waveform_url: 'https://wave.sndcdn.com/XcNSeimeVBLt_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 504,
      download_count: 0,
      favoritings_count: 10,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 206468705,
      created_at: '2015/05/21 01:20:12 +0000',
      user_id: 2712198,
      duration: 153640,
      commentable: true,
      comment_count: 1,
      state: 'finished',
      original_content_size: 31134679,
      last_modified: '2020/07/30 21:48:24 +0000',
      sharing: 'public',
      tag_list: 'epanalepsis ost videogame game gamemusic soundtrack',
      permalink: '09-technopenderecki',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: 'https://audiosprite.bandcamp.com/album/epanalepsis',
      purchase_title: null,
      label_id: null,
      genre: 'microtonal',
      title: 'Epanalepsis - Technopenderecki',
      description:
        'The soundtrack to Cameron Kunzelman\'s literary adventure game of the same name. store.steampowered.com/app/333690/\ncredits\nreleased 21 May 2015 \n\nJohn Fio: clarinet, bari sax, acoustic guitar, bass guitar, piano, drums, electric guitar, all programming \nSam Tobias: flute, alto sax \nDan Filipak: double bass \nKyle Kassick: additional guitars on "Heaven" \n\nrecorded and mixed by John Fio \nmastered by Jose Ignacio Alfaro',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2015,
      release_month: 5,
      release_day: 16,
      original_format: 'flac',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/206468705',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/09-technopenderecki',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000271272239-7zeyh6-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/206468705/stream',
      download_url: 'https://api.soundcloud.com/tracks/206468705/download',
      waveform_url: 'https://wave.sndcdn.com/NUGpq1Jk0Sn1_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 295,
      download_count: 0,
      favoritings_count: 2,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 398078469,
      created_at: '2018/02/12 01:46:52 +0000',
      user_id: 2712198,
      duration: 9142,
      commentable: true,
      comment_count: 1,
      state: 'finished',
      original_content_size: 2613188,
      last_modified: '2018/06/08 20:00:58 +0000',
      sharing: 'public',
      tag_list: 'rock chiptune chipmetal',
      permalink: 'roll-for-crit-chiprock',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'chiprock',
      title: 'Roll for Crit (chiprock)',
      description:
        'Genre variations of the Roll for Crit video & podcast series theme.\n\nhttps://www.youtube.com/user/RollForCritCast',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/398078469',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/roll-for-crit-chiprock',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000300757320-xbez85-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/398078469/stream',
      download_url: 'https://api.soundcloud.com/tracks/398078469/download',
      waveform_url: 'https://wave.sndcdn.com/a31hg7v2rnIZ_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 125,
      download_count: 0,
      favoritings_count: 3,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 21901984,
      created_at: '2011/08/25 23:47:16 +0000',
      user_id: 2712198,
      duration: 12229,
      commentable: true,
      comment_count: 9,
      state: 'finished',
      original_content_size: 486504,
      last_modified: '2021/02/18 16:58:49 +0000',
      sharing: 'public',
      tag_list:
        'noise rock "thrash jazz" thrash jazz "noise rock" "alto sax" sax "grind " grindjazz',
      permalink: 'my-name-is-kyle',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'thrashjazz',
      title: 'My Name is Kyle',
      description: 'dedicated to my friend kyle',
      label_name: '',
      release: '',
      track_type: null,
      key_signature: '',
      isrc: null,
      video_url: null,
      bpm: 208,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'mp3',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/21901984',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/my-name-is-kyle',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000084792941-uetatr-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/21901984/stream',
      download_url: 'https://api.soundcloud.com/tracks/21901984/download',
      waveform_url: 'https://wave.sndcdn.com/bOAO0z4ikHaV_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 6004,
      download_count: 27,
      favoritings_count: 27,
      reposts_count: 4,
      downloadable: true,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 206468657,
      created_at: '2015/05/21 01:19:44 +0000',
      user_id: 2712198,
      duration: 270992,
      commentable: true,
      comment_count: 2,
      state: 'finished',
      original_content_size: 58127436,
      last_modified: '2020/07/30 21:48:39 +0000',
      sharing: 'public',
      tag_list:
        'epanalepsis ost videogame game gamemusic soundtrack Noise "noise rock"',
      permalink: '04-heaven',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: 'https://audiosprite.bandcamp.com/album/epanalepsis',
      purchase_title: null,
      label_id: null,
      genre: 'noiserock',
      title: 'Epanalepsis - Heaven',
      description:
        'The soundtrack to Cameron Kunzelman\'s challenging and literary adventure game of the same name. store.steampowered.com/app/333690/\ncredits\nreleased 21 May 2015 \n\nJohn Fio: clarinet, bari sax, acoustic guitar, bass guitar, piano, drums, electric guitar, all programming \nSam Tobias: flute, alto sax \nDan Filipak: double bass \nKyle Kassick: additional guitars on "Heaven" \n\nrecorded and mixed by John Fio \nmastered by Jose Ignacio Alfaro',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2015,
      release_month: 5,
      release_day: 17,
      original_format: 'flac',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/206468657',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/04-heaven',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000362619012-x7736d-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/206468657/stream',
      download_url: 'https://api.soundcloud.com/tracks/206468657/download',
      waveform_url: 'https://wave.sndcdn.com/qUmzBjRVupSw_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 250,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 1,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 635307246,
      created_at: '2019/06/12 00:04:41 +0000',
      user_id: 2712198,
      duration: 98856,
      commentable: true,
      comment_count: 1,
      state: 'finished',
      original_content_size: 3951488,
      last_modified: '2019/06/14 06:04:50 +0000',
      sharing: 'public',
      tag_list: 'stringquartet sq "string quartet" "pop chamber"',
      permalink: 'florabora',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'chamber',
      title: 'Florabora',
      description:
        'Light chamber tune for string quartet done for a game in the Global Game Jam 2019 at NYU.\n\nPhoto by Gina Moffit.',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'mp3',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/635307246',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url: 'https://soundcloud.com/audiosprite/florabora',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000550526586-ndfxvj-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/635307246/stream',
      download_url: 'https://api.soundcloud.com/tracks/635307246/download',
      waveform_url: 'https://wave.sndcdn.com/BA2cb0jiqthV_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 61,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 659519219,
      created_at: '2019/08/01 12:42:36 +0000',
      user_id: 2712198,
      duration: 24740,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 7107744,
      last_modified: '2019/08/10 15:28:51 +0000',
      sharing: 'public',
      tag_list: '"rocky " conan',
      permalink: 'sword-coast-coast-2-coast',
      streamable: true,
      embeddable_by: 'all',
      purchase_url: null,
      purchase_title: null,
      label_id: null,
      genre: 'power 70s',
      title: 'Sword Coast: Coast 2 Coast',
      description:
        "Theme song for Ranged Touch's D&D program SWORD COAST: COAST 2 COAST http://rangedtouch.com/category/sword-coast-coast-2-coast/",
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: null,
      release_month: null,
      release_day: null,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/659519219',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/sword-coast-coast-2-coast',
      artwork_url:
        'https://i1.sndcdn.com/artworks-000575861585-lyzuck-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/659519219/stream',
      download_url: 'https://api.soundcloud.com/tracks/659519219/download',
      waveform_url: 'https://wave.sndcdn.com/29tJ14WsAcsr_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 116,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
    {
      kind: 'track',
      id: 911695285,
      created_at: '2020/10/16 15:09:52 +0000',
      user_id: 2712198,
      duration: 7837,
      commentable: true,
      comment_count: 0,
      state: 'finished',
      original_content_size: 1499910,
      last_modified: '2020/12/25 04:17:29 +0000',
      sharing: 'public',
      tag_list: '',
      permalink: 'super-crome-bullet-purgatory',
      streamable: true,
      embeddable_by: 'all',
      purchase_url:
        'https://audiosprite.bandcamp.com/album/super-crome-bullet-purgatory-ep',
      purchase_title: null,
      label_id: null,
      genre: 'chiptune',
      title: 'Super Crome: Bullet Purgatory - Stage Clear',
      description:
        'Soundtrack EP for space shmup SUPER CROME: BULLET PURGATORY now available in early access on Steam: https://store.steampowered.com/app/984450/Super_Crome_Bullet_Purgatory/',
      label_name: null,
      release: null,
      track_type: null,
      key_signature: null,
      isrc: null,
      video_url: null,
      bpm: null,
      release_year: 2020,
      release_month: 10,
      release_day: 15,
      original_format: 'wav',
      license: 'all-rights-reserved',
      uri: 'https://api.soundcloud.com/tracks/911695285',
      user: {
        id: 2712198,
        kind: 'user',
        permalink: 'audiosprite',
        username: 'John Fio',
        last_modified: '2020/06/21 22:57:46 +0000',
        uri: 'https://api.soundcloud.com/users/2712198',
        permalink_url: 'https://soundcloud.com/audiosprite',
        avatar_url:
          'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
      },
      user_uri: 'https://api.soundcloud.com/users/2712198',
      permalink_url:
        'https://soundcloud.com/audiosprite/super-crome-bullet-purgatory',
      artwork_url:
        'https://i1.sndcdn.com/artworks-yTQiNzBLkm5ky6nW-RGNBFA-large.jpg',
      stream_url: 'https://api.soundcloud.com/tracks/911695285/stream',
      download_url: 'https://api.soundcloud.com/tracks/911695285/download',
      waveform_url: 'https://wave.sndcdn.com/7j6g7DyfsrhK_m.png',
      domain_lockings: null,
      available_country_codes: null,
      label: null,
      secret_token: null,
      secret_uri: null,
      user_favorite: null,
      user_playback_count: null,
      playback_count: 7,
      download_count: 0,
      favoritings_count: 0,
      reposts_count: 0,
      downloadable: false,
      downloads_remaining: null,
    },
  ],
  playlist_type: '',
  id: 310569779,
  tracks_uri: 'https://api.soundcloud.com/playlists/310569779/tracks',
  downloadable: null,
  sharing: 'public',
  created_at: '2017/03/26 17:58:22 +0000',
  release: null,
  likes_count: 0,
  kind: 'playlist',
  title: 'Reel',
  type: '',
  purchase_title: null,
  artwork_url: 'https://i1.sndcdn.com/artworks-000214756627-7amnw5-large.jpg',
  ean: null,
  streamable: true,
  user: {
    permalink_url: 'https://soundcloud.com/audiosprite',
    permalink: 'audiosprite',
    username: 'John Fio',
    uri: 'https://api.soundcloud.com/users/2712198',
    last_modified: '2020/06/21 22:57:46 +0000',
    id: 2712198,
    kind: 'user',
    avatar_url: 'https://i1.sndcdn.com/avatars-000589520781-kvaq4l-large.jpg',
  },
  embeddable_by: 'all',
  label_id: null,
};
